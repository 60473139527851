.wrapper {
  display: flex;
  gap: 16px;
  background: #272A3F;
  font-family: 'Proxima Nova', sans-serif;
}

.icon {
  flex-shrink: 0;
}

.title {
  font-weight: 600;
}

.message {
  font-size: 14px;
  margin-top: 4px;
}
