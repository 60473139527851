.inputWrapper {
  position: relative;
  width: 290px;
}

.smallLabel {
  position: absolute;
  top: 0;
  left: 19px;
  font-size: 10px;
  background: #FFF;
  border-radius: 4px;
  padding: 4px;
  opacity: 0;
  transition: all 200ms ease-in-out;
  pointer-events: none;
}

.input:not(:placeholder-shown) + .smallLabel {
  top: 0;
  opacity: 1;
}

.label {
  font-size: 16px;
  font-weight: 600;
  color: #36394F;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.input {
  width: calc(100% - 46px);
  background-color: #FFF;
  padding: 17px 23px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  user-select: none;
  border-radius: 4px;
  border: 0;
  outline: 0;
}

.input::placeholder {
  color: #6E718A;
}

.input::-webkit-inner-spin-button {
  appearance: none;
}
